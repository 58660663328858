import React, { Component } from 'react';
import {Client, authHeader} from '../backend'

class Users extends Component {
    constructor(props) {
      super(props);
      this.state = {
        success: false,
        data: [],
        editing: []
      };
    }
  
    /*
      INITIALIZATION
    */

    componentWillMount() {
        // fetch user list from backend here if user is logged in and is admin
        this.loadAdvertisers()

    }

    loadAdvertisers(){
      Client.get('users/', authHeader())
      .then((resp) =>{
        // success
        console.log(resp); 
        this.setState({
         success: true,
         data: resp.data
         }); 
      })
      .catch( (error) => {
        // error
        console.log(error); 
        this.setState({
         error: error
         }); 
      })
    }
  
    // { id: 3, email: "dog@dog.com", username: "test", ad_units: 0 }


    /*
      EDIT ADVERTISER
    */

      start_edit(id){
        this.state.editing.push(id) //id: -1 for creating a new
        this.setState({
          editing: this.state.editing
        })
      }
  
      cancel_edit(id){
        var index = this.state.editing.indexOf(id);
        if (index !== -1) {
          this.state.editing.splice(index, 1);
        }
        this.setState({
          editing: this.state.editing
        })
      }
  
      finish_edit(event){
        console.log(event)
        event.preventDefault(); // Prevent sitereload
  
        // Extract advertiser ID
        const id = event.target.name
        const data = event.target
  

        let units = parseInt(data[1].value)
        // Assamble form object
        const newUser = {
          email: data[0].value,
          ad_units: units
        }
  
        // sent to backend
        Client.put('/users/'+id+'/',newUser,authHeader()).then((resp) => {
          console.log(resp)
          // reload advertisers
          this.loadAdvertisers()
        }).catch((error) => {
          console.log(error)
        })
  
        // Stop editing advertiser
        var index = this.state.editing.indexOf(parseInt(id));
        if (index !== -1) {
          this.state.editing.splice(index, 1);
        }
  
        this.setState({
          editing: this.state.editing
        })
  
      }

    /*
      DELETE ADVERTISER
    */

      handle_delete(id){
        Client.delete('/users/'+id+'/',authHeader()).then((resp) => {
          console.log(resp)
          this.loadAdvertisers() // Reload site
        }).catch((error) => {
          console.log(error)
        })
      }



    /*
      CREATE/EDIT ADVERTISER
    */

      renderAdvertiserCreationFields(advertiser, i){

        // Create a new
        if(!advertiser){
          // Show edit fields if button is pressed
          if(this.state.editing.includes(-1)){
            return(
            <form onSubmit={this.saveNewAdvertiser.bind(this)} name={-1}>
                      <table>
                      <tbody>
            <tr key={i}>
              <td><input type="text" defaultValue="Käyttäjänimi"/></td>
              <td><input type="email" defaultValue="example@example.com"/></td>
              <td><input type="number" defaultValue="0" min="0"/></td>
              <td>
                <input type="submit" value="Tallenna"/>
                <button onClick={ () => this.cancel_edit(-1)}>Peruuta</button>
                </td>
            </tr>
            </tbody>
            </table>
            </form>
            )
          }
          // Show add new button if edit fields not visible
          else{
            return(<button className="new" onClick={ () => this.start_edit(-1)}>Lisää uusi</button>)
          }
        }
        // Edit existing
        else{
          return(
            <form onSubmit={this.finish_edit.bind(this)} name={advertiser.id}>
                      <table>
                      <tbody>
            <tr key={i}>
              <td>{advertiser.username}</td>
              <td><input type="email" defaultValue={advertiser.email}/></td>
              <td><input type="number" defaultValue={advertiser.ad_units} min="0"/></td>
              <td>
                <input type="submit" value="Tallenna"/>
                <button onClick={ () => this.cancel_edit(advertiser.id)}>Peruuta</button>
                </td>
            </tr>
            </tbody>
            </table>
            </form>
          )
        }
  
      }


    /*
      SAVE NEW ADVERTISER
    */

    saveNewAdvertiser(event){
      console.log(event)
      event.preventDefault(); // Prevent sitereload

      const data = event.target

      // Assamble form object
      const newUser = {
        username: data[0].value,
        email: data[1].value,
        ad_units: data[2].value
      }

      // sent to backend
      Client.post('/users/',newUser,authHeader()).then((resp) => {
        console.log(resp)
        // reload advertisers
        this.loadAdvertisers()
      }).catch((error) => {
        console.log(error)
      })

      // Stop editing advertiser
      var index = this.state.editing.indexOf(-1);
      if (index !== -1) {
        this.state.editing.splice(index, 1);
      }

      this.setState({
        editing: this.state.editing
      })
    }
  
    /*
      RENDER
    */

      render() {
        if(this.state.error){
          return( <h2 className="error">Virhe</h2>)
        }
  
       return(
        <>
          <h1>Mainostajat</h1>
          <table>
          <thead>
            <tr>
              <th>Käyttäjänimi:</th>
              <th>Sähköposti:</th>
              <th>Mainosyksiköt:</th>
              <th>Toiminnot:</th>
            </tr>
          </thead>
          </table>
          {this.renderAdvertiserCreationFields()}
          {this.state.data.map((advertiser,i) => {
            console.log(advertiser)
            if(this.state.editing.includes(advertiser.id)){
              return(this.renderAdvertiserCreationFields(advertiser, i))
            }
            else{
              return(
                <table>
                <tbody>
                <tr key={i}>
                  <td>{advertiser.username}</td>
                  <td>{advertiser.email}</td>
                  <td>{advertiser.ad_units}</td>
                  <td>
                    <button onClick={ () => this.start_edit(advertiser.id)}>Muokkaa</button>
                    {advertiser.id !== 1 ?  <button onClick={ () => this.handle_delete(advertiser.id)}>Poista</button> : <></>}
                    </td>
                </tr>
                </tbody>
                </table>
              )
            }
          })}
        </>
       )
      }
  }
  
  export default Users;