import React, { Component } from 'react';
import LoginForm from './LoginForm';
import SignupForm from './SignupForm';

import {Client,authHeader} from '../backend'

import './../styles/app.scss';
import logo from './../dogmap_logo_2048_white_transparent.png';

class Login extends Component {
    constructor(props) {
      super(props);
      this.state = {
        error:'',
      };
    }
  

  
    handle_login = (e, data) => {
        e.preventDefault();
        Client.post('/',{username: data.username, password: data.password})
        .then((resp) =>{
          // success
          localStorage.setItem('token', resp.data.token);
          // Then get user details
          this.props.callback(resp.data)
        })
        .catch( (error) => {
          // error
          console.log(error); 
          this.setState({
           error: 'Salasana tai käyttäjänimi ei täsmää'
           }); 

        })
      }

        // Old
        /*
        fetch('http://localhost:8000/token-auth/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
        })
          .then(res => res.json())
          .then(json => {
            localStorage.setItem('token', json.token);
            this.setState({
              logged_in: true,
              displayed_form: '',
              username: json.user.username
            });
          });
      };
      */

      /*
      handle_signup = (e, data) => {
        e.preventDefault();
        Client.post('core/users/')
        .then((resp) =>{
          // success
          localStorage.setItem('token', resp.token);
          this.setState({
            logged_in: true,
            username: resp.username
          });
        })
        .catch( (error) => {
          // error
          console.log(error); 
          this.setState({
           error: error
           }); 
        })
      }
      */
      // OLD
      /*
      handle_signup = (e, data) => {
        e.preventDefault();
        fetch('http://localhost:8000/core/users/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
        })
          .then(res => res.json())
          .then(json => {
            localStorage.setItem('token', json.token);
            this.setState({
              logged_in: true,
              displayed_form: '',
              username: json.username
            });
          });
      };
      */
  
    render() {
        let form;
        switch ('login') {
          case 'login':
            form = <LoginForm handle_login={this.handle_login} />;
            break;
          case 'signup':
            form = <SignupForm handle_signup={this.handle_signup} />;
            break;
          default:
            form = null;
        }
     return(
        <>
            <img className="logo" src={logo} alt="Logo image" height={300} width={300} />
            <h1>Hallintapaneeli</h1>
            {form}
            <span>{this.state.error}</span>
        </>
     )
    }
  }
  
  export default Login;


