import axios from 'axios';

export const baseURL = 'https://www.dogmap.app:8080/'

export const Client = axios.create({
	baseURL: baseURL,
	responseType: 'json',
   withCredentials: true
})

export const authHeader = () =>{
	let token = localStorage.getItem('token')
	console.log(token)
	return(
		{
	headers: {
		Authorization: `Token ${token}`
	  }}

)
}

export const headerMultipart = () =>{

return({
	headers:{
		'content-type': 'multipart/form-data',
		Authorization: `Token ${localStorage.getItem('token')}`
	}
})}