import React, { Component } from 'react';
import {Client,authHeader} from '../backend'

class Settings extends Component {
    constructor(props) {
      super(props);
      this.state = {

      };
    }
  

    finish_edit(event){
      console.log(event)
      event.preventDefault(); // Prevent sitereload

      // Extract data from form

      const data = event.target

      // Validate password
      if(data[1].value !== data[2].value){
        alert("Salasanat eivät täsmää")
        return;
      }

      if(data[1].value.length <8){
        alert("Salasanan tulee olla vähintään 8 merkkiä pitkä")
        return;
      }

      const newSettings = {
        username: this.props.loggedin.user.username,
        email: data[0].value,
        password: data[1].value,

      }

      // Commit to backend
      Client.put('/user/',newSettings,authHeader()).then((resp) => {
        console.log(resp)
      }).catch((error) => {
        console.log(error)
      })

    }

  
    render() {
      console.log(this.props)
     return(
     <div>
       <h1>Asetukset:</h1>

        <form onSubmit={this.finish_edit.bind(this)} name="user_settings">
        <p>Sähköpostiosoite:</p>
        <input type="email" defaultValue={this.props.loggedin.user.email}/>
        <p>Vaihda salasana:</p>
        <input type="password"/>
        <p>Vahvista salasana:</p>
        <input type="password"/>
        <br/><br/>
        <input type="submit" value="Tallenna" className="pointer"/>
        </form>
     </div>)
    }
  }
  
  export default Settings;