import React, { Component } from 'react';
import {Client, authHeader, baseURL, headerMultipart} from '../backend'

class Coupons extends Component {
    constructor(props) {
      super(props);
      this.state = {
        data: [],
        advertisers:[],
        editing: [],
        ad_units_used: 0,
        image: null
      };
    }


    /*
      INITIALIZATION
    */
  
    componentWillMount() {
        // fetch list of advertisers if superuser
        if(this.isSuperUser()){
          Client.get('users/', authHeader())
          .then((resp) =>{
            // success
            console.log(resp); 
            this.setState({
              advertisers: resp.data
             }); 
          })
          .catch( (error) => {
            // error
            console.log(error); 
          })
        }
        // load coupons
        this.loadCoupons()
    }

    loadCoupons(){
        // fetch coupons from backend here if user is logged in
        //console.log(authHeader)
        // load all or own depending if user is superuser
        Client.get(this.isSuperUser() === true ? '/coupons/' : '/coupons/user_created', authHeader())
        .then((resp) =>{
          // success
          console.log(resp); 
          // Count ad_units_spent
            let totalSpent=0;
            resp.data.map((coupon) => {totalSpent += coupon.ad_units})
          // apply to state
          this.setState({
           success: true,
           data: resp.data,
           ad_units_used: totalSpent,
           }); 
        })
        .catch( (error) => {
          // error
          console.log(error); 
          this.setState({
           error: error
           }); 
        })
    }
  
    // [{"id":1,"title":"testikuponki1","description":"kuvaus","code":"123","image":"/media/coupon_images/Screenshot_20200611-180802_i4m2Bq3.jpg","coupon_url":"http://www.google.com","ad_units":100,"expires_after_days":30,"given_count":0,"view_count":0,"advertiser":1},{"id":2,"title":"testikuponki2","description":"testaus","code":"abc","image":"/media/coupon_images/photo-1593642532842-98d0fd5ebc1a_gOdzsmJ.jpg","coupon_url":"http://www.google.com","ad_units":100,"expires_after_days":60,"given_count":0,"view_count":0,"advertiser":1},{"id":3,"title":"testikuponki3","description":"description","code":"abc123","image":"/media/coupon_images/photo-1631114228333-8ecb5f0d7588_DFWPaG0.jpg","coupon_url":"http://www.google.com","ad_units":100,"expires_after_days":27,"given_count":0,"view_count":0,"advertiser":1}]
  
    /*
      GET ADVERTISER BY ID
    */

      getAdvertiserById(id){
        let advertiser = id;
        this.state.advertisers.forEach(element => {
          if(element.id === id){
            advertiser = element.username
          }
          return
        })

        return advertiser
      }

    /*
      USER IS SUPER USER?
    */

      isSuperUser(){
        //console.log(this.props.loggedin.user.isSuperUser === true)
        if(this.props.loggedin.user  && this.props.loggedin.user.isSuperUser === true){return true}
        return false
      }


    /*
      DISPLAY UNALLOCATED AD_UNITS
    */

    display_ad_units(){
      //skip if superuser
      if(this.isSuperUser() === true){ return(<></>)} 

      let totalUnits = this.props.loggedin !== undefined? this.props.loggedin.user.ad_units: 0
      //render ad_units
      return(
        <div className="ad_units">
          <h3>Mainosyksiköt:</h3>
          <p>Yhteensä: {totalUnits}</p>
          <p>Käytetty: {this.state.ad_units_used}</p>
          <p>Vapaana: {totalUnits - this.state.ad_units_used}</p>
        </div>
      )
    }

    remaining_ad_units(){
      if(this.isSuperUser() === true){return(99999999999999)} 
      let val = this.props.loggedin !== undefined? this.props.loggedin.user.ad_units - this.state.ad_units_used : 0
      if(val < 0){val = 0}
      return val
    }

    /*
      EDIT COUPON
    */

    start_edit(id){
      this.state.editing.push(id) //id: -1 for creating a new
      this.setState({
        editing: this.state.editing
      })
    }

    cancel_edit(id){
      var index = this.state.editing.indexOf(id);
      if (index !== -1) {
        this.state.editing.splice(index, 1);
      }
      this.setState({
        editing: this.state.editing
      })
    }

    finish_edit(event){
      console.log(event)
      event.preventDefault(); // Prevent sitereload

      // Extract coupon ID
      const id = event.target.name
      const data = event.target

      // Assamble form object
      const newcoupon = {
        title: data[0].value,
        description: data[1].value,
        code: data[2].value,
        url: data[3].value,
        ad_units: data[4].value,
        expires_after_days: data[5].value,
      }

      // sent to backend
      Client.put('/coupons/'+id+'/',newcoupon,authHeader()).then((resp) => {
        console.log(resp)
        // reload coupons
        this.loadCoupons()
      }).catch((error) => {
        console.log(error)
      })

      // Stop editing coupon
      var index = this.state.editing.indexOf(parseInt(id));
      if (index !== -1) {
        this.state.editing.splice(index, 1);
      }

      this.setState({
        editing: this.state.editing
      })

    }

    /*
      DELETE COUPON
    */

    handle_delete(id){
      Client.delete('/coupons/'+id+'/',authHeader()).then((resp) => {
        console.log(resp)
        this.loadCoupons() // Reload site
      }).catch((error) => {
        console.log(error)
      })
    }


    /*
      CREATE/EDIT COUPON
    */

    renderCouponCreationFields(coupon, i){

      // Create a new
      if(!coupon){
        // Show edit fields if button is pressed
        if(this.state.editing.includes(-1)){
          return(
          <form onSubmit={this.saveNewCoupon.bind(this)} name={-1}>
                    <table>
                    <tbody>
          <tr key={i}>
            <td><input type="text" defaultValue="Otsikko"/></td>
            <td><input type="text" defaultValue="Kuvaus"/></td>
            <td><input type="text" defaultValue="Tarjouskoodi"/></td>
            <td><input type="url" defaultValue="Linkki"/></td>
            <td><input type="number" defaultValue="1" min="0" max={this.remaining_ad_units()}/></td>
            <td><input type="number" defaultValue="30" min="1"/></td>
            <td></td>
            <td></td>
            {this.isSuperUser() === true? <td></td> : <></>}
            <td><input type="file"
                accept="image/png, image/jpeg" onChange={ (event)=> { this.uploadImage(event,this)}}/></td>
            <td>
              <input type="submit" value="Tallenna"/>
              <button onClick={ () => this.cancel_edit(-1)}>Peruuta</button>
              </td>
          </tr>
          </tbody>
          </table>
          </form>
          )
        }
        // Show add new button if edit fields not visible
        else{
          return(<button className="new" onClick={ () => this.start_edit(-1)}>Lisää uusi</button>)
        }
      }
      // Edit existing
      else{
        return(
          <form onSubmit={this.finish_edit.bind(this)} name={coupon.id}>
                    <table>
                    <tbody>
          <tr key={i}>
            <td><input type="text" defaultValue={coupon.title}/></td>
            <td><input type="text" defaultValue={coupon.description}/></td>
            <td><input type="text" defaultValue={coupon.code}/></td>
            <td><input type="url" defaultValue={coupon.url}/></td>
            <td><input type="number" defaultValue={coupon.ad_units} min="0" max={this.remaining_ad_units()}/></td>
            <td><input type="number" defaultValue={coupon.expires_after_days} min="1"/></td>
            <td>{coupon.given_count}</td>
            <td>{coupon.view_count}</td>
            {this.isSuperUser() === true? <td>{this.getAdvertiserById(coupon.advertiser)}</td> : <></>}
            <td><img src={baseURL + coupon.image}/></td>
            <td>
              <input type="submit" value="Tallenna"/>
              <button onClick={ () => this.cancel_edit(coupon.id)}>Peruuta</button>
              </td>
          </tr>
          </tbody>
          </table>
          </form>
        )
      }

    }

    /*
      IMAGE
    */

    
    uploadImage(ev,reference){
      /*
      console.log(ev)
      var reader = new FileReader();
      reader.onload = function() {
        var base64js = require('base64-js')
        var arrayBuffer = this.result,
          array = new Uint8Array(arrayBuffer),
          binaryString = base64js.fromByteArray(array)

        console.log(binaryString)
        //reference.setState({image: binaryString})
        reference.setState({image: this.result})
      }
      if(ev.target && ev.target.files.length > 0){
      reader.readAsArrayBuffer(ev.target.files[0])
      //console.log(ev.target.files[0] instanceof Blob)
      //reference.setState({image: ev.target.files[0]})
      //reader.readAsText(ev.target.files[0])
      }
      */
    }
    
    /*
      SAVE NEW COUPON
    */

    saveNewCoupon(event){
      console.log(event)
      event.preventDefault(); // Prevent sitereload

      const data = event.target
      let newcoupon = undefined
/*       // Get image
      if(this.state.image){

      // Assamble form object
       newcoupon = {
        title: data[0].value,
        description: data[1].value,
        code: data[2].value,
        url: data[3].value,
        ad_units: data[4].value,
        expires_after_days: data[5].value,
        //image: data[6].files[0]
        image: this.state.image
      }
    }
    else{ */
            // Assamble form object
             newcoupon = {
              title: data[0].value,
              description: data[1].value,
              code: data[2].value,
              url: data[3].value,
              ad_units: data[4].value,
              expires_after_days: data[5].value,

            }
    //}

      //formdata
      let formdata = new FormData()
      for ( var key in newcoupon ) {
        formdata.append(key, newcoupon[key]);
      }
      // Get image
      if(data[6].files.length>0){
        formdata.append("image", data[6].files[0]);
      }

      // sent to backend
      Client.post('/coupons/',formdata,headerMultipart()).then((resp) => {
        console.log(resp)
        // reload coupons
        this.loadCoupons()
      }).catch((error) => {
        console.log(error)
      })

      // Stop editing coupon
      var index = this.state.editing.indexOf(-1);
      if (index !== -1) {
        this.state.editing.splice(index, 1);
      }

      this.setState({
        editing: this.state.editing
      })
    }

    /*
      RENDER
    */

    render() {
      if(this.state.error){
        return( <h2 className="error">Virhe</h2>)
      }
      

     return(
      <div className="container">
        <h1>Kupongit</h1>

        {this.display_ad_units()}
        <hr/>
        <table>
        <thead>
          <tr>
            <th>Otsikko:</th>
            <th>Kuvaus:</th>
            <th>Tarjouskoodi:</th>
            <th>Linkki:</th>
            <th>Mainosyksiköt:</th>
            <th>Voimassaoloaika päivissä:</th>
            <th>Myönnettyjä lkm.:</th>
            <th>Katselukerrat lkm.:</th>
            {this.isSuperUser() === true? <th>Mainostaja:</th> : <></>}
            <th>Kuva:</th>
            <th>Toiminnot:</th>
          </tr>
        </thead>
        </table>
        {this.renderCouponCreationFields()}
        {this.state.data.map((coupon,i) => {
          console.log(coupon)
          if(this.state.editing.includes(coupon.id)){
            return(this.renderCouponCreationFields(coupon, i))
          }
          else{
            return(
              <table>
              <tbody>
              <tr key={i}>
                <td>{coupon.title}</td>
                <td>{coupon.description}</td>
                <td>{coupon.code}</td>
                <td>{coupon.url}</td>
                <td>{coupon.ad_units}</td>
                <td>{coupon.expires_after_days}</td>
                <td>{coupon.given_count}</td>
                <td>{coupon.view_count}</td>
                {this.isSuperUser() === true? <td>{this.getAdvertiserById(coupon.advertiser)}</td> : <></>}
                <td><img src={baseURL + coupon.image}/></td>
                <td>
                  <button onClick={ () => this.start_edit(coupon.id)}>Muokkaa</button>
                  <button onClick={ () => this.handle_delete(coupon.id)}>Poista</button>
                  </td>
              </tr>
              </tbody>
              </table>
            )
          }
        })}
      </div>
     )
    }
  }
  
  export default Coupons;

