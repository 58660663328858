import React, { Component } from 'react';
import './styles/app.scss';

import logo from './dogmap_logo_2048_color_transparent.png';

//import './styles/index.scss';
import {Client,authHeader} from './backend'

// Sivut
import Coupons from './components/Coupons';
import Users from './components/Users'
import Login from './components/Login';
import Settings from './components/Settings';
import Dashboard from './components/Dashboard';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect
} from "react-router-dom";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logged_in: localStorage.getItem('token') ? true : false, 
      user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')):{
        username: '',
        userId: -1,
        isSuperUser: false,
        ad_units: 0}
    };
    this.callback = this.onLoggedIn.bind(this)
  }

  componentWillMount() {
/*     if (this.state.logged_in) {
      Client.post('core/current_user/', authHeader)
        .then((resp) =>{
          // success
          console.log(resp); 
          this.setState({
            username: resp.username
          });
        })
        .catch( (error) => {
          // error
          console.log(error); 
          this.setState({
           error: error,
           logged_in: false
           }); 
        })
    } */
  }


  onLoggedIn= (data) => { // TODO: GET FROM ACTUAL DATA DATA!
    console.log(data)

    const userobj ={
      username: data.username,
      userId: data.id,
      isSuperUser: data.id === 1 ? true: false,
      ad_units: data.ad_units,
      email: data.email
    } 

    localStorage.setItem('user', JSON.stringify(userobj));

    this.setState({
      logged_in: true,
      user: userobj
    });
  }

  handle_logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    this.setState({ logged_in: false,       user:{
      username: '',
      userId: -1,
      isSuperUser: false,
      ad_units: 0}});
  };

  display_form = form => {
    this.setState({
      displayed_form: form
    });
  };

  render() {
    return (
      <div className="App">


          <div>


          </div>
          {this.state.logged_in
            ? LoggedInPaths(this.state,this.props,this.handle_logout)
            : LoggedOutPaths(this.state,this.props,this.callback)}

      </div>
    );
  }
}

export default App;


const LoggedInPaths = (state ,props, handle_logout) =>{

  let showUsers = <></>;
  if(state.user && state.user.isSuperUser){
    showUsers = <li>
    <Link to="/users">Käyttäjät</Link>
  </li>;
  }

  return(
    <Router>
      {/* Navigaatiojutut tähän. nää näkyy vaan kun käyttäjä on kirjautunut sisää */}
      <nav>
        <ul>
        <img src={logo} alt="Logo image" height={50} width={50} />
          {/* <li>
            <Link to="/">Etusivu</Link>
          </li> */}
          <li>
            <Link to="/coupons">Kupongit</Link>
          </li>
            {showUsers}
          <li>
            <Link to="/settings">Asetukset</Link>
          </li>
          <li>
            <Link to="/" onClick={handle_logout}>Kirjaudu ulos</Link>
          </li>
        </ul>
      </nav>
      {/* Routeri alasivuineen ja niitte osoittenee */}
      <Switch>
        {/* Kupongitsivu */}
        <Route path="/coupons">
          <Coupons loggedin={state}/>
        </Route>
        {/* Käyttäjähallintasivu */}
        <Route path="/users">
          <Users loggedin={state}/> {/* TODO, check that user is admin */}
        </Route>
        {/* Asetukset */}
        <Route path="/settings">
          <Settings loggedin={state}/> {/* TODO, check that user is admin */}
        </Route>
        {/* Pääsivu */}
        <Route exact path="/">
          <Dashboard loggedin={state}/>
        </Route>
        {/* Redirectaa aina pääsivulle jos urli on jotai ihan muuta */}
        <Route path="*">
            <Redirect to="/" />
        </Route>
      </Switch>
    </Router>
    )
}

const LoggedOutPaths = (state, props, callback) =>{
console.log(callback)
  return(
    <Router>
      <Switch>
        <Route path="/login">
          <Login props={state} callback={callback}  />
        </Route>
        {/* Kun käyttäjä ei ole kirjautunut sisään, redirectaa /loginiin */}
        <Route path="*">
          <Redirect to="/login" />
        </Route>

      </Switch>
    </Router>
    )
}