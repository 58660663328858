import React, { Component } from 'react';

class Dashboard extends Component {
    constructor(props) {
      super(props);
      this.state = {

      };
    }
  
    componentDidMount() {

    }
  

  
    render() {
     return(<>Päänäkymä?</>)
    }
  }
  
  export default Dashboard;